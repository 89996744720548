import '../css/app.css'
import 'lazysizes'
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

Alpine.plugin(persist)

Alpine.store('app', {
  mobile: false,
  filter: false,
  isMobile: false,
  filterIsSet: false,
  xUri: Alpine.$persist(''),
  wishlist: Alpine.$persist([]),
  toggleMobile () {
    this.filter = false
    this.mobile = !this.mobile
  },
  toggleFilter () {
    this.mobile = false
    this.filter = !this.filter
  },
  checkIsMobile () {
    this.isMobile = window.innerWidth < 1024 ? false : true
    this.filter = this.isMobile
  },
  readCookie () {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith('lastSearchUri=')) {
        this.xUri = decodeURIComponent(cookie.substring('lastSearchUri='.length, cookie.length))
      }
    }
  },
  addToWishlist (entryId) {
    if (this.wishlist.includes(entryId)) {
      this.wishlist = this.wishlist.filter(function (value) {
        return entryId !== value
      })
    } else {
      this.wishlist.push(entryId)
    }
    this.getWishlistResult()
  },
  checkIsInWishlist (entryId) {
    return this.wishlist.includes(entryId)
  },
  getWishlistResult () {
    if (document.getElementById('wishlisthtmlresult')) {
      const uri = '/wishlistresult?entries=' + this.wishlist.join(',')
      fetch(uri)
        .then((response) => response.text())
        .then((html) => {
          document.getElementById('wishlisthtmlresult').innerHTML = html
        })
        .catch((error) => console.error(error))
    }
  },
})

// Example script
// <script class="podigee-podcast-player" src="https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js" data-configuration="https://kinderschutzpodcast.podigee.io/39-neue-episode/embed?context=external"></script>

Alpine.data('audio', () => ({
  audioUrl: null,
  playAudio () {
    const script = document.createElement('script')
    script.async = false
    script.setAttribute(
      'src',
      'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js',
    )
    script.setAttribute('class', 'podigee-podcast-player')
    script.setAttribute('data-configuration', this.audioUrl + '/embed?context=external')
    this.$refs.box.innerHTML = ''
    this.$refs.box.appendChild(script)
  },
}))

Alpine.data('video', () => ({
  videoUrl: null,
  playVideo () {
    let src = ''
    const video = this.parseVideo(this.videoUrl)
    const iframe = document.createElement('iframe')

    if (video.type === 'youtube') {
      src = `//www.youtube-nocookie.com/embed/${video.id}?rel=0&amp;autoplay=1`
    } else if (video.type === 'vimeo') {
      src = `//player.vimeo.com/video/${video.id}?autoplay=1`
    }
    iframe.setAttribute('src', src)
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
    )
    iframe.setAttribute('allowfullscreen', 1)
    this.$refs.box.innerHTML = ''
    this.$refs.box.appendChild(iframe)
  },
  parseVideo (url) {
    let type
    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com|be\-nocookie\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
    )
    if (RegExp.$3.indexOf('youtu') > -1) {
      type = 'youtube'
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      type = 'vimeo'
    }

    return {
      type: type,
      id: RegExp.$6,
    }
  },
}))

Alpine.start()

// Greensock
const gsapSeite = document.getElementById('infoandfacts')
if (gsapSeite) {
  // GRAFIK 1
  const grafik1 = gsap.timeline({
    delay: 1,
    scrollTrigger: {
      once: true,
      trigger: '.motion-section-circle-dotted',
      markers: false,
      start: 'top 80%',
    },
  })
  grafik1.from('.motion-circle', {
    autoAlpha: 0,
    rotation: 45,
    scaleX: 0.8,
    scaleY: 0.8,
    duration: 0.7,
  })
  grafik1.from('.motion-circle-knete', {
    autoAlpha: 0,
    scaleX: 0.5,
    scaleY: 0.5,
    ease: 'back.out(1)',
    duration: 0.3,
  })
  grafik1.from('.motion-box', { autoAlpha: 0, stagger: 0.1, duration: 0.3, y: -20 })
  grafik1.from('.motion-flash', {
    autoAlpha: 0,
    duration: 0.3,
    delay: 0.3,
    y: -10,
  })

  // GRAFIK 2
  const grafik2 = gsap.timeline({
    delay: 0.5,
    scrollTrigger: {
      once: true,
      trigger: '.motion-section-circle-houses',
      markers: false,
      start: 'top 80%',
    },
  })
  grafik2.from('.motion-haus-knete', {
    autoAlpha: 0,
    scaleX: 0.5,
    scaleY: 0.5,
    ease: 'back.out(1)',
    duration: 0.3,
  })
  grafik2.from('.motion-haus', {
    autoAlpha: 0,
    ease: 'back.out(1)',
    stagger: 0.1,
  })
  grafik2.from('.motion-netzwerk', {
    autoAlpha: 0,
    scaleX: 0.5,
    scaleY: 0.5,
    ease: 'back.out(1)',
    duration: 0.5,
  })

  // GRAFIK 3
  const grafik3 = gsap.timeline({
    delay: 0.5,
    scrollTrigger: {
      once: true,
      trigger: '.motion-section-bricks',
      markers: false,
      start: 'top 70%',
    },
  })
  grafik3.from('.motion-bricks-dach', {
    delay: 0.1,
    autoAlpha: 0,
    y: -50,
    ease: 'back.out(1)',
    duration: 0.5,
  })
  grafik3.from('.motion-bricks-schornstein', {
    delay: 0.1,
    autoAlpha: 0,
    y: -50,
    ease: 'back.out(1)',
    duration: 0.5,
  })
  grafik3.from('.motion-baustein', {
    autoAlpha: 0,
    duration: 0.3,
    delay: 0.3,
    y: -20,
    stagger: 0.1,
  })
  grafik3.from('.motion-bricks-waende', {
    delay: 0.1,
    autoAlpha: 0,
    scaleX: 1.5,
    scaleY: 1.5,
    ease: 'back.out(1)',
    duration: 0.5,
  })
  grafik3.from('.motion-bricks-tuer', {
    delay: 0.1,
    autoAlpha: 0,
    scaleX: 0.5,
    scaleY: 0.5,
    ease: 'back.out(1)',
    duration: 0.5,
  })
  grafik3.from('.motion-bricks-fenster', {
    delay: 0.1,
    autoAlpha: 0,
    scaleX: 0.5,
    scaleY: 0.5,
    ease: 'back.out(1)',
    duration: 0.5,
  })

  // Headlines
  const headlines = gsap.utils.toArray('.motion-section-headline')
  headlines.forEach((item) => {
    let headline = item.querySelectorAll('.motion-headline')
    gsap.from(headline, {
      autoAlpha: 0,
      duration: 0.3,
      delay: 0.3,
      y: -10,
      stagger: 0.3,
      scrollTrigger: {
        // once: true,
        trigger: item,
        markers: false,
        start: 'top 80%',
      },
    })
  })

  // Divider
  const divider = gsap.utils.toArray('.motion-section-divider')
  divider.forEach((item) => {
    let svg = item.querySelector('.motion-svg')
    gsap.from(svg, {
      autoAlpha: 0,
      duration: 0.3,
      delay: 0.3,
      y: -10,
      scrollTrigger: {
        // once: true,
        trigger: item,
        markers: false,
        start: 'top 80%',
      },
    })
  })

  // Accordions
  const accordions = gsap.utils.toArray('.motion-section-accordion')
  accordions.forEach((item) => {
    let accordionElements = item.querySelectorAll('.motion-accordion')
    gsap.from(accordionElements, {
      autoAlpha: 0,
      duration: 0.3,
      delay: 0.3,
      y: -10,
      stagger: 0.3,
      scrollTrigger: {
        // once: true,
        trigger: item,
        markers: false,
        start: 'top 80%',
      },
    })
  })
}
